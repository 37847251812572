import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";
import type { WaitlistResponse } from "features/waitlists/types";
import useCurrentUser from "hooks/useCurrentUser";
import type { PaginatedQueryResult } from "react-query";
import { usePaginatedQuery } from "react-query";
import type { ErrorResponse } from "types";
import handleRequestError from "./helpers/handleRequestError";

export const getCurrentUserWaitlistEntries = async (
  _: unknown,
  page: number,
  limit: number
) => {
  const response: AxiosResponse<WaitlistResponse> = await axios.get(
    `/api/user/waitlist-entries?page=${page}&limit=${limit}`
  );
  return response.data;
};

export default function useCurrentUserWaitlistEntries(
  page: number,
  limit: number
): PaginatedQueryResult<WaitlistResponse, AxiosError<ErrorResponse>> {
  const { data: user } = useCurrentUser();

  return usePaginatedQuery(
    ["currentUserWaitlistEntries", page, limit],
    getCurrentUserWaitlistEntries,
    {
      onError: error => {
        handleRequestError(error);
      },
      enabled: !!user?._id
    }
  );
}
