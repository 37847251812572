import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ButtonHTMLAttributes } from "react";
import { forwardRef } from "react";
import { cn } from "utils/cn";

// Based on https://tailwindui.com/components/application-ui/elements/buttons
const buttonVariants = cva("rounded font-semibold", {
  variants: {
    variant: {
      default:
        "bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:pointer-events-none disabled:opacity-50",
      secondary: "bg-white text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50",
      soft: "bg-indigo-50 text-indigo-600 hover:bg-indigo-100",
      outline:
        "border border-input bg-background hover:brightness-[97%] hover:text-accent-foreground",
      destructive: "bg-red-600 text-white hover:bg-red-500",
      calendar:
        "border border-input bg-background hover:brightness-[97%] hover:text-accent-foreground ring-0 outline-0 shadow-none",
      ghost: "hover:bg-accent hover:text-accent-foreground",
      link: "text-primary underline-offset-4 hover:underline",
      unstyled: ""
    },
    size: {
      xs: "px-2 py-1 text-xs",
      sm: "px-2 py-1 text-sm",
      default: "px-2.5 py-1.5 text-sm",
      lg: "px-3 py-2 text-sm",
      xl: "px-3.5 py-2.5 text-sm",
      icon: "h-10 w-10",
      unstyled: ""
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
